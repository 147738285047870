import React, { useState, useEffect } from "react";

function HomeSecond() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width <= 1120);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      const handleScrollAppear = () => {
        const rows = document.querySelectorAll(".displayFlexRow");

        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                entry.target.classList.add("appear");
              }
            });
          },
          {
            threshold: 0.7, // 요소가 화면에 50% 이상 보일 때 적용
          }
        );

        rows.forEach((row) => {
          observer.observe(row);
        });

        return () => {
          rows.forEach((row) => {
            observer.unobserve(row);
          });
        };
      };

      handleScrollAppear();
    }
  }, [isMobile]);

  return (
    <section className={`newsecond ${isMobile ? "mobile-view" : ""}`}>
      <div className={`${isMobile ? "" : "sectionInner"}`}>
        <div className="newsecLeft ">
          <div className="newsecLeftTitle">
            퍼널스는 오직 치과 <span>만</span>
          </div>
          <div className="newsecLeftDetail sectionTitle">
            수치화 할 수 있는
            <br className="mobile-br" />
            <br className="pc-br" /> 퍼포먼스 마케팅으로
            <br className="mobile-br" />
            <br className="pc-br" />{" "}
            <span className="moblieHightlight">매출 상승을 약속합니다</span>
          </div>
        </div>
        <div className="newsecRight">
          <div className="displayFlexRow">
            <div className="pc_newsecNumber">01</div>
            <div className="mobile_newsecIcon">
              <img
                src={`${process.env.PUBLIC_URL}/Images/22/mobile_onlyfunnels1.png`}
                alt="DB 마케팅"
              />
            </div>
            <div>
              <div className="newsecdetailtitle">DB 마케팅</div>
              <div className="newsecdetaildetail">
                미디어믹스를 통한 예산분배와
                <br className="mobile-br" />
                <br className="pc-br" />
                실시간 관리 시스템으로 효율 극대화
              </div>
            </div>
          </div>
          <div className="displayFlexRow">
            <div className="pc_newsecNumber">02</div>
            <div className="mobile_newsecIcon">
              <img
                src={`${process.env.PUBLIC_URL}/Images/22/mobile_onlyfunnels2.png`}
                alt="전문 TM 부서"
              />
            </div>
            <div>
              <div className="newsecdetailtitle">전문 TM 부서</div>
              <div className="newsecdetaildetail">
                15년차 위생사가 상황별 응대메뉴얼 관리
                <br className="pc-br" />
                <br className="mobile-br" />
                신/구환 CS 완벽 관리
              </div>
            </div>
          </div>{" "}
          <div className="displayFlexRow">
            <div className="pc_newsecNumber">03</div>
            <div className="mobile_newsecIcon">
              <img
                src={`${process.env.PUBLIC_URL}/Images/22/mobile_onlyfunnels3.png`}
                alt="상위 노출 / 바이럴"
              />
            </div>
            <div>
              <div className="newsecdetailtitle">검색 최적화</div>
              <div className="newsecdetaildetail">
                네이버 검색 영역 상위 장악
                {/* <br className="mobile-br" />
                <br className="pc-br" />
                유지 및 보장 */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeSecond;
